'use client';

import { Fragment, useState } from 'react';
import clsx from 'clsx';
import { MdArrowBackIos } from 'react-icons/md';
import { type Cart } from 'shared/types/cart';
import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import CartTotal from '~/components/furniturechoice/cart-pricing/CartTotal';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import Link from '~/components/furniturechoice/link';
import PaymentMethod from '~/components/furniturechoice/payment-methods';
import SectionHeader from '~/components/furniturechoice/section-header';
import UniqueCustomerCode from '~/components/furniturechoice/unique-customer-code';
import DigicertIcon from '~/components/icons/digicert';
import EcologiIcon from '~/components/icons/ecologi-tree';
import { useCart } from '~/frontastic/hooks';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { getPriceSummary } from '~/helpers/furniturechoice/displayPriceHelper';
import { useFormat } from '~/helpers/hooks/useFormat';
import { DataSourceV2 } from '~/types/datasource';
import CartItem from './cart-item';
import WarrantyItem from './warranty-item';
import checkIfWarrantyMismatchExists from './warranty-validation';
import WarrantyValidationModal from './warranty-validation-modal';
import { TasticProps } from '../../../types';
import ZeroFinanceLabel from '../../product/content-manager/main-details/zero-finance-label';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CartProp {
  financeTerms?: DataSourceV2<FinanceProduct[]>;
}

export default function Cart({ data, params }: TasticProps<CartProp>) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const cart = useCart();
  const [isModalHidden, setModalHidden] = useState<boolean>(true);
  const activeDiscount =
    cart.data && cart.data.discountCodes && cart.data.discountCodes.length ? cart.data.discountCodes[0] : null;
  const { subtotalAmount, discountAmount } = getPriceSummary(cart);
  async function validateCart() {
    //if there is a mismatch, show warning modal. if not, go to checkout.
    if (checkIfWarrantyMismatchExists(cart)) {
      setModalHidden(false);
    } else {
      window.location.href = '/checkout';
    }
  }
  //console.log(cart);
  let discountText = <></>;

  if (activeDiscount) {
    discountText = (
      <>
        <div className="flex items-baseline justify-between">
          <span className="text-16 font-semibold">
            {formatMessage({ id: 'components.unique-customer-code.subtotal' })}
          </span>
          <div className="text-right leading-tight">
            <span className="text-16 font-semibold text-grey-5">
              {cart.data?.sum &&
                CurrencyHelpers.formatForCurrency(
                  subtotalAmount,
                  params.locale,
                  cart.transaction.subtotal.currencyCode,
                  cart.transaction.subtotal.fractionDigits,
                )}
            </span>
          </div>
        </div>
        <div className="flex items-baseline justify-between">
          <span className="text-16 font-semibold">
            {formatMessage({ id: 'components.unique-customer-code.discount' })} {activeDiscount.code}
          </span>
          <div className="text-right leading-tight">
            <span className="text-16 font-semibold text-grey-5">
              -
              {CurrencyHelpers.formatForCurrency(
                discountAmount,
                params.locale,
                cart.transaction.subtotal.currencyCode,
                cart.transaction.subtotal.fractionDigits,
              )}
            </span>
          </div>
        </div>
        <div className="my-4 border-b-2 border-grey-3"></div>
      </>
    );
  }

  return (
    <section data-testid="cart" className="container mx-auto">
      <div className="flex flex-col gap-y-5 py-8 text-left leading-tight lg:text-center">
        <SectionHeader as="h1">
          <div className="flex items-end gap-x-2">
            <span>{formatMessage({ id: 'tastics.cart.cart-content-manager.basket' })}</span>

            {cart.data?.lineItems && cart.data.lineItems.length > 0 && (
              <small className="font-sans text-14 font-normal text-grey-4">
                ({cart.data?.totalLineItemQuantity} {formatMessage({ id: 'tastics.cart.cart-content-manager.items' })})
              </small>
            )}
          </div>
        </SectionHeader>

        <PaymentMethod className="mx-auto mt-1 flex gap-2 lg:justify-center" />
      </div>

      {!cart.isEmpty ? (
        <div className="grid grid-cols-5 gap-8 lg:pb-12">
          <div className="col-span-5 grid gap-y-8 lg:col-span-3">
            {cart.data?.lineItems?.map((i) => (
              <Fragment key={(i.lineItemId ?? '') + (i.count ?? '0')}>
                <CartItem productData={i} locale={params.locale} />
                {cart.data?.lineItems &&
                  i.variant.attributes?.['related_warranty'] &&
                  !cart.data.lineItems.some(
                    (item) => item.variant.sku == i.variant.attributes?.['related_warranty'],
                  ) && <WarrantyItem warrantySKU={i.variant.attributes?.['related_warranty']} />}
              </Fragment>
            ))}
          </div>
          <div className="col-span-5 ml-2 lg:col-span-2">
            <div className="rounded bg-grey-1 p-4">
              <div key={activeDiscount?.code || 'none'}>{discountText}</div>
              <div className="flex items-baseline justify-between">
                <span className="text-20 font-bold">Total:</span>
                <div className="text-right leading-tight">
                  <CartTotal
                    className={clsx('inline text-20 font-bold', {
                      'text-red-1': cart.data?.lineItems?.find((i) => i.discountedPrice),
                    })}
                    cart={cart.data}
                    locale={params.locale}
                  />
                  <span className="block text-right text-12 font-normal text-grey-4">
                    {formatMessage({ id: 'tastics.cart.cart-content-manager.including_vat' })}
                  </span>
                </div>
              </div>
              <div className="flex text-12 text-salmon-4">
                <span className="ml-auto pt-2 font-light">
                  <ZeroFinanceLabel
                    key={cart.data?.sum?.centAmount}
                    financeProducts={data.financeTerms?.dataSource}
                    financeableAmount={cart.data?.sum}
                    onClick={() => {
                      const button = document.getElementById('zero_finance');
                      if (button) button.click();
                    }}
                  />
                </span>
              </div>

              <FNCButton className="surface-green-2 mb-4 mt-6 w-full rounded-sm" onClick={() => validateCart()}>
                {formatMessage({ id: 'tastics.cart.cart-content-manager.pay_securely_now' })}
              </FNCButton>

              <div>
                <UniqueCustomerCode />
              </div>

              <div className="mt-1 flex justify-between">
                <PaymentMethod className="flex gap-2" />
                <DigicertIcon />
              </div>
            </div>
            <div className="my-4 rounded bg-grey-1 p-4">
              <a className="" href="#">
                <div className="flex items-start justify-center">
                  <EcologiIcon className="mr-2" />
                  <p>
                    <strong>Together let’s plant 500,000 trees</strong> — One for every order!
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="my-6 text-center" data-testid="empty-basket-state">
          <span className="mt-3 text-center font-normal text-grey-5">
            {formatMessage({ id: 'tastics.cart.cart-content-manager.your_basket_is_empty' })}
          </span>
          <span className="flex pt-2">
            <Link
              href="/"
              className="mx-auto flex items-center text-center text-14 font-normal text-salmon-4 hover:underline"
            >
              <MdArrowBackIos className="inline-block" />
              {formatMessage({ id: 'tastics.cart.cart-content-manager.continue_shopping' })}
            </Link>
          </span>
        </div>
      )}

      <WarrantyValidationModal isOpen={!isModalHidden} onModalClose={() => setModalHidden(true)} />
    </section>
  );
}
