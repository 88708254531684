import { UseCartReturn } from '~/frontastic/hooks/useCart/types';

interface ExpectedWarrantyCount {
  warranty: string;
  matches: number;
}

function countExpectedWarrantyItems(cart: UseCartReturn): ExpectedWarrantyCount[] {
  return (
    cart.data?.lineItems?.reduce(
      (r, item) => {
        const warranty = item.variant.attributes?.['related_warranty'];
        if (warranty) {
          const { arr, map } = r;
          const i = map.get(warranty);
          i
            ? (i.matches += item.count ?? 1)
            : map.set(warranty, (arr[arr.length] = { warranty, matches: item.count ?? 1 }));
        }

        return r;
      },
      { arr: [], map: new Map() } as { arr: ExpectedWarrantyCount[]; map: Map<string, ExpectedWarrantyCount> },
    ).arr ?? []
  );
}

export default function checkIfWarrantyMismatchExists(cart: UseCartReturn): boolean {
  //count number of instances of items with warranty
  const counts = countExpectedWarrantyItems(cart);

  //check if the amount of warranties is not equal to the number of items
  let hasMismatch = false;
  counts?.forEach((expectedWarrantyCount) => {
    const warranty = cart.data?.lineItems?.find((x) => x.variant.sku == expectedWarrantyCount.warranty);
    if (warranty && (warranty.count ?? 0) != expectedWarrantyCount.matches) {
      hasMismatch = true;
    }
  });

  return hasMismatch;
}
