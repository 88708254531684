import { useContext } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { MdClose } from 'react-icons/md';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import CartPricing from '~/components/furniturechoice/cart-pricing';
import QuantitySelector from '~/components/furniturechoice/inputs/quantity-selector';
import ProductMainImage from '~/components/furniturechoice/product-main-image';
import { useCart } from '~/frontastic/hooks';
import ReviewWidgetCart from '~/frontastic/tastics/furniturechoice/product/content-manager/extra-details/review-table/ReviewWidgetCart';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';

interface CartItemProps {
  productData: FNCLineItem;
  locale: string;
}

export default function CartItem({ productData, locale }: CartItemProps): JSX.Element {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const cartApi = useCart();
  const isSwatch = productData.variant?.attributes?.['descriptors_reporting-group2'] == 'SWA';

  function handleRemoveItemClick() {
    if (productData.lineItemId) {
      executeWithProgress(() => cartApi.removeItem(productData.lineItemId as string));
    }
  }

  const handleQuantityUpdate = debounce((newQty: number) => {
    if (newQty > 0) {
      executeWithProgress(() => cartApi.updateItem(productData.lineItemId ?? '', newQty));
    } else {
      handleRemoveItemClick();
    }
  }, 1000);

  return (
    <div data-testid="basket-item" className="relative grid grid-cols-7 border-t border-grey-3 pt-4">
      <div className={'col-span-2'}>
        {productData.variant?.images && (
          <Link href={'/p' + productData._url ?? '#'}>
            <ProductMainImage
              className={clsx('w-full', {
                'rounded-full': isSwatch,
                'rounded-sm': !isSwatch,
              })}
              sku={productData.variant.sku}
              colSpanPercent={0.35}
              colSpanPercentLg={0.2}
            />
          </Link>
        )}
      </div>
      <div className="relative col-span-5 px-4">
        {/* Placeholder for rating */}
        <span className="text-12 text-grey-4">Code: {productData.variant.sku}</span>
        <div className="my-1 h-[20px]">
          <ReviewWidgetCart product={productData} />
        </div>
        <Link className="block text-16 font-semibold leading-tight" href={'/p' + productData._url ?? '#'}>
          {productData.name}
        </Link>
        {/* Placeholder for stock info label */}
        <div className="py-2 pr-6 text-12 leading-tight">
          <span className="font-semibold text-green-2">In stock.</span>
          <span> Order now for </span>
          <span className="font-semibold text-green-2">free delivery </span>
          <span className="font-semibold text-grey-5">in 3-5 working days.</span>
          <span className="text-grey-4"> Select your delivery date at checkout.</span>
        </div>
        <div className="mt-0 flex items-center justify-between">
          {productData.count && (
            <QuantitySelector
              quantity={productData.count}
              minValue={0}
              onCountUpdate={handleQuantityUpdate}
              isSwatch={isSwatch}
            />
          )}
          <div className="text-right leading-tight">
            <CartPricing item={productData} locale={locale} />
          </div>
        </div>
        {!isSwatch && (
          <div className="mt-4 leading-4 text-grey-5">
            <span className="mb-1 block text-14 font-semibold"> Free returns </span>
            <span className="block text-12"> 30-day, full money-back guarantee on all products. </span>
          </div>
        )}
        <button className="absolute right-0 top-0 text-grey-5" onClick={handleRemoveItemClick}>
          <MdClose size={20} />
        </button>
      </div>
    </div>
  );
}
