'use client';

import { HTMLAttributes, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { MdClose, MdOutlineWarningAmber } from 'react-icons/md';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import { useFormat } from '~/helpers/hooks/useFormat';

interface WarratyValidationModal extends HTMLAttributes<HTMLDialogElement> {
  isOpen: boolean;
  onModalClose: () => void;
}

export default function WarratyValidationModal({
  isOpen,
  onModalClose,
  ...props
}: WarratyValidationModal): JSX.Element {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  useEffect(() => {
    const dialogEl = dialogRef.current as unknown as HTMLDialogElement;
    if (isOpen) {
      dialogEl.show();
    } else {
      dialogEl.close();
    }
  }, [isOpen]);

  return (
    <>
      <dialog
        ref={dialogRef}
        className={clsx('fixed inset-0 z-600 items-center justify-center bg-transparent backdrop:bg-backdrop', {
          hidden: !isOpen,
        })}
        inert={!isOpen ? '' : undefined}
        onClick={(event) => event.target === dialogRef.current && onModalClose()}
        data-testid="warranty-validation-modal"
        {...props}
      >
        <div className="flex h-[378px] w-[311px] rounded-md bg-white p-3 scrollbar-hide lg:h-[280px] lg:w-[574px]">
          <button
            className="pointer-events-auto absolute right-0 top-0 cursor-pointer rounded rounded-r-none bg-white p-2"
            onClick={onModalClose}
            aria-label="close"
          >
            <MdClose size={24} />
          </button>
          <div className="flex h-full w-full flex-col gap-6 p-3 lg:gap-5">
            <MdOutlineWarningAmber size={48} className="fill-salmon-5" />
            <h2 className="text-18 font-bold">
              {formatMessage({ id: 'tastics.cart.warranty-validation-modal.header' })}
            </h2>
            <p className="text-grey-5">{formatMessage({ id: 'tastics.cart.warranty-validation-modal.description' })}</p>
            <div className="w-full border-b-2 border-grey-3" />
            <div className="grid w-full grid-cols-1 gap-2 lg:grid-cols-2">
              <FNCButton
                className="surface-green-2 h-[48px] rounded-lg font-light lg:order-last"
                onClick={onModalClose}
              >
                {formatMessage({ id: 'tastics.cart.warranty-validation-modal.return' })}
              </FNCButton>
              <FNCLinkButton className="surface-grey-4 h-[48px] rounded-lg font-light" href="/checkout">
                {formatMessage({ id: 'tastics.cart.warranty-validation-modal.proceed' })}
              </FNCLinkButton>
            </div>
          </div>
        </div>
      </dialog>

      {isOpen && <div className="fixed inset-0 z-[595] bg-backdrop" onClick={onModalClose} />}
    </>
  );
}
