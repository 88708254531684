import { useState, useContext } from 'react';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { MdCheckCircleOutline } from 'react-icons/md';
import { FNCProduct } from 'shared/types/product/Product';
import { useCart } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import useProduct from '~/helpers/hooks/furniturechoice/use-product';
import { useFormat } from '~/helpers/hooks/useFormat';

interface WarrantyItemProps {
  warrantySKU: string;
}

export default function WarrantyItem({ warrantySKU }: WarrantyItemProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { locale } = useParams();
  const [isCollapsed, setCollapsed] = useState<boolean>(true);
  const warrantyProduct = useProduct({ sku: warrantySKU });
  const cart = useCart();
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  const warrantyVariant = warrantyProduct.data?.selectedVariant;

  async function addToCart() {
    await executeWithProgress(() => cart.addItem((warrantyProduct.data as FNCProduct).selectedVariant, 1));
  }

  return (
    <>
      {warrantyProduct.data && warrantyVariant && (
        <div data-testid="warranty-item" className="surface-grey-2 rounded-md">
          <div className="flex items-start gap-3 p-4 lg:items-center lg:p-3">
            <button
              className="surface-green-1 max-h-[34px] min-h-[34px] min-w-[58px] max-w-[58px] rounded-md"
              onClick={addToCart}
            >
              <span className="text-14 font-bold text-white">
                {formatMessage({ id: 'tastics.cart.warranty-item.add' })}
              </span>
            </button>
            <div className="flex-auto">
              <span className="text-14 font-semibold lg:font-bold">{warrantyProduct.data.name}</span>
            </div>
            <div>
              <span className="text-16 font-semibold lg:text-18 lg:font-bold">
                {CurrencyHelpers.formatForCurrency(
                  warrantyVariant.discountedPrice || warrantyVariant.price || 0,
                  locale,
                )}
              </span>
            </div>
          </div>
          <div className="px-4 pb-4 lg:px-3 lg:pb-3">
            <span className="text-justify text-12 text-grey-5 lg:text-14">
              {warrantyVariant.attributes?.['descriptors_long-description']}
            </span>
          </div>
          <div className="mx-3 border-b-2 border-grey-3" />
          <div className={clsx('p-4 lg:p-3', { hidden: isCollapsed })}>
            <div className="py-3">
              <span className="text-18 font-bold leading-[22px]">
                {formatMessage({ id: 'tastics.cart.warranty-item.coverage_header' })}
              </span>
              <div className="grid grid-cols-3">
                {(warrantyVariant.attributes?.['descriptors_coverage'] as string[]).map((coverage, index) => (
                  <div className="flex items-center gap-1 pt-3 lg:gap-3" key={index}>
                    <MdCheckCircleOutline
                      size={24}
                      className="max-h-[24px] min-h-[24px] min-w-[24px] max-w-[24px] fill-green-1"
                    />
                    <div className="text-12 lg:text-14">{coverage}</div>
                  </div>
                ))}
              </div>
            </div>

            {/* TODO: Fetch using Contentful in descriptors_exclusion */}
            <div className="mt-3">
              <span className="text-16 font-bold leading-[22px] lg:text-18">
                {formatMessage({ id: 'tastics.cart.warranty-item.exclusions_header' })}
              </span>
              <div className="mt-3">
                <span className="text-justify text-12 text-grey-5 lg:text-14">
                  Wear and tear, deliberate damage, abuse, misuse, neglect, commercial use.
                </span>
              </div>
              <div className="mt-3">
                <span className="text-justify text-12 text-grey-5 lg:text-14">
                  By purchasing the Care Plan, you confirm that you have reviewed the Insurance Product Information
                  Document (IPID) and that it meets your Demands and Needs. You confirm you do not want to pay to
                  restore your furniture in the event of an accidental stain or if it becomes accidentally damaged, that
                  you do not wish to use your home insurance as you may incur an excess and a potential impact on your
                  renewal, and that you understand the Care Plan is not a general cleaning contract but a policy that
                  provides cover for specific incidents. View the full terms and conditions.
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center p-4 lg:items-start lg:justify-normal lg:p-3">
            <button className="text-12 font-bold text-grey-6 underline" onClick={() => setCollapsed(!isCollapsed)}>
              {isCollapsed
                ? formatMessage({ id: 'tastics.cart.warranty-item.show_more' })
                : formatMessage({ id: 'tastics.cart.warranty-item.show_less' })}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
